<template>
 <page-layout
 :title="this.$t('general.label.translations')"
 :fixed-footer="getTotalPages > 1">
  <template #header-actions>
      <base-button
        type="primary"
        prepend-icon="ri-add-line"
        :label="$t('translations.add_button')"
        @click.stop="addTranslation"
      ></base-button>

    </template>
    <base-section>
      <el-form inline>
        <base-select
          :label="this.$t('translations.input.with')"
          v-model="select"
          :items="selectModes"
          @input="fetchData"
        />

        <base-input :label="this.$t('translations.input.like')" v-model="input">
          <template #prependInput>
            <i class="ri-search-line"></i>
          </template>
        </base-input>
      </el-form>
    </base-section>
    <el-table
      ref="table"
      :data.sync="getTranslations"
      @row-click="editTranslation"
      row-class-name="table-row clickable"
    >
      <el-table-column
        prop="key"
        :label="$t('translations.column.key')"
        show-overflow-tooltip
      />
      <el-table-column
        :label="$t('translations.column.default_value')"
        show-overflow-tooltip
      >
        <template slot-scope="{row}">{{ row.label.defaultValue }}</template>
      </el-table-column>
      <el-table-column fixed="right" :width="150" class-name="actions-cell">
        <template slot-scope="{row}">
          <base-button
            @click.stop="handleDelete(row)"
            size="small"
            prepend-icon="ri-delete-bin-2-line"
          />
          <base-button
            prepend-icon="ri-pencil-line"
            size="small"
            :label="$t('general.button_label.edit')"
            @click.stop="editTranslation(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <template #footer v-if="getTotalPages > 1">
      <base-pagination v-model="pagination.page" :pages="getTotalPages" @input="fetchData"/>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import { debounce } from 'lodash'
import CancelRequest from '@/mixins/CancelRequest'

import { confirmDefaultOptions } from '../app/helpers'
import { mapActions, mapGetters } from 'vuex'
import TableLoader from '@/mixins/TableLoader'

export default {
  name: 'TranslationsViewAll',
  components: { PageLayout },
  mixins: [CancelRequest, TableLoader],
  data () {
    return {
      selectModes: [{ value: 'key' }, { value: 'translation' }],
      select: 'key',
      input: '',
      pagination: {
        page: 0,
        size: 25
      }
    }
  },
  computed: {
    ...mapGetters({
      getTranslations: 'translations/getTranslations',
      getTotalPages: 'translations/getTotalPages'
    }),
    formByKey () {
      return {
        key: this.input,
        pagination: this.pagination
      }
    },
    formByTranslation () {
      return {
        translation: this.input,
        pagination: this.pagination
      }
    }
  },
  watch: {
    input: debounce(function () {
      this.fetchData()
    }, 700)
  },
  methods: {
    ...mapActions({
      getByKey: 'translations/getByKey',
      getByTranslation: 'translations/getByTranslation',
      delete: 'translations/delete',
      clearTranslationState: 'translations/clearTranslationState'
    }),
    handleGetByKey () {
      return this.getByKey({
        form: this.formByKey,
        options: {
          cancelToken: this.CancelRequest_token()
        }
      })
    },
    handleGetByTranslation () {
      return this.getByTranslation({
        form: this.formByTranslation,
        options: {
          cancelToken: this.CancelRequest_token()
        }
      })
    },
    async fetchData () {
      this.TableLoader_show()
      try {
        if (this.select === 'key') {
          await this.handleGetByKey()
        } else {
          await this.handleGetByTranslation()
        }
      } catch (e) {}
      this.TableLoader_hide()
    },
    handleDelete (row) {
      this.$confirm(
        this.$t('translations.confirmation.text', {
          key: row.key,
          defaultValue: row.label.defaultValue
        }),

        this.$t('translations.confirmation.title'),
        {
          ...confirmDefaultOptions,
          confirmButtonClass: 'base-button el-button--danger'
        }
      ).then(async (res) => {
        if (res) {
          await this.delete({
            form: { key: row.key },
            options: {
              successNotification: this.$t(
                'translations.delete_translation.success_notification'
              )
            }
          })
          this.fetchData()
        }
      })
    },
    addTranslation () {
      this.$router.push({
        name: 'AddOrEditTranslaiton',
        params: {
          type: 'Add'
        },
        query: {
          type: 'add',
          buttonText: this.$t('translations.button.add'),
          title: this.$t('translations.add.title')
        }
      })
    },
    editTranslation (row) {
      this.$router.push({
        name: 'AddOrEditTranslaiton',
        params: {
          type: 'edit'
        },
        query: {
          type: 'edit',
          buttonText: this.$t('general.button_label.save_changes'),
          title: this.$t('translations.edit.title'),
          labelTranslations: row.label.labelTranslations,
          key: row.key,
          defaultValue: row.label.defaultValue
        }
      })
    }
  },
  mounted () {
    this.handleGetByKey()
  },
  beforeDestroy () {
    this.clearTranslationState()
  }
}
</script>
