<template>
  <page-layout :title="$t('user_fee.title')" :fixed-footer="true">
    <template #header-actions>
      <base-button
        prepend-icon="ri-add-line"
        type="primary"
        :label="$t('user_fee.add_button_main')"
        @click.stop="handleAddUserFee"
      ></base-button>
    </template>
    <el-table ref="table" :data.sync="tableData">
      <el-table-column
        prop="assigneeOspId"
        :label="$t('user_fee.assignee')"
        :min-width="150"
        show-overflow-tooltip
      />
      <el-table-column prop="eventCountThresholdFrom" :label="$t('user_fee.from_threshold')" />
      <el-table-column prop="eventCountThresholdTo" :label="$t('user_fee.to_threshold')" />
      <el-table-column prop="feeAmount" :label="$t('user_fee.ammount_fee')" />
      <el-table-column prop="vatPercentage" :label="$t('user_fee.vat')" />
      <el-table-column fixed="right" class-name="actions-cell" :width="150">
        <template slot-scope="{row}">
          <base-button
            size="small"
            @click.stop="handleDeleteUserFee(row)"
            prepend-icon="ri-delete-bin-2-line"
          />
          <base-button
            prepend-icon="ri-pencil-line"
            size="small"
            :label="$t('general.button_label.edit')"
            @click.stop="handleEditUserFee(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <template #footer v-if="totalPages > 1">
      <base-pagination v-model="pagination.page" :pages="totalPages" />
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '../../../layouts/PageLayout'
import TableLoader from '@/mixins/TableLoader'

import { confirmDefaultOptions } from '@/app/helpers'

export default {
  name: 'UserFeeViewAll',
  mixins: [TableLoader],
  components: {
    PageLayout
  },
  data () {
    return {
      pagination: {
        page: 0,
        size: 25
      },
      data: []
    }
  },
  computed: {
    totalPages () {
      try {
        return Math.ceil(this.data.length / this.pagination.size)
      } catch (e) {
        return 1
      }
    },
    tableData () {
      return this.data.slice(
        this.pagination.size * this.pagination.page,
        this.pagination.size * (this.pagination.page + 1)
      )
    }
  },
  methods: {
    handleAddUserFee () {
      this.$router.push({
        name: 'AddOrEditUserFee',
        params: { type: 'add-user-fee' },
        query: {
          type: 'Add',
          buttonText: this.$t('user_fee.add_button'),
          title: this.$t('user_fee.add_title')
        }
      })
    },
    handleEditUserFee (row) {
      this.$router.push({
        name: 'AddOrEditUserFee',
        params: { type: 'edit' },
        query: {
          type: 'Edit',
          from: row.eventCountThresholdFrom,
          to: row.eventCountThresholdTo,
          fee: row.feeAmount,
          vat: row.vatPercentage,
          id: row.assigneeOspId,
          buttonText: this.$t('general.button_label.save_changes'),
          title: this.$t('user_fee.update_title')
        }
      })
    },
    handleDeleteUserFee (row) {
      this.$confirm(
        this.$t('user_fee.delete_text'),
        this.$t('user_fee.delete_title'),
        {
          ...confirmDefaultOptions,
          confirmButtonText: this.$t('translations.confirmation.delete'),
          confirmButtonClass: 'base-button el-button--danger'
        }
      ).then((res) => {
        this.$api.userFee.delete(
          {
            assigneeOspId: row.assigneeOspId,
            eventCountThresholdFrom: row.eventCountThresholdFrom,
            eventCountThresholdTo: row.eventCountThresholdTo,
            feeAmount: row.feeAmount,
            vatPercentage: row.vatPercentage
          },
          {
            onSuccess: () => {
              this.fetchData()
            }
          }
        )
      })
    },
    fetchData () {
      this.TableLoader_show()
      this.$api.userFee.getAll({
        onSuccess: (res) => {
          this.data = res
        },
        onFinally: () => {
          this.TableLoader_hide()
        }
      })
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>
